import React from 'react'
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

function ChordFunctionLabel({ degree }: { degree: number }) {
    let bgColor: string = '';
    let text: string = '';
    let label: string;
    let tooltip: string = '';
    switch (degree) {
        case 1:
        case 3:
        case 6:
            text = 'T';
            bgColor = 'dark:bg-cyan-800 bg-cyan-400'
            tooltip = 'Tonic chords are chords the feel like home base and provides a sense of closure and resolution'
            break;
        case 2:
        case 4:
            bgColor = 'dark:bg-teal-700 bg-teal-400'
            text = 'S';
            tooltip = 'Subdominant chords are chords that create a sense of contrast by moving away from home base and creating an anticipation or pull towards the dominant chord'
            break;
        case 5:
        case 7:
            bgColor = 'dark:bg-amber-700 bg-amber-400'
            text = 'D';
            tooltip = 'Dominant chords are chords with a sense of tension and instability that naturally wants to resolve to the tonic chord'
            break;
    }
    return (
        <Tooltip placement="top" trigger={['click']} overlay={<span>{tooltip}</span>}>
            <div className={`${bgColor} text-xs w-5 h-5 leading-5 rounded mr-1 text-white cursor-pointer hover:brightness-150`}>{text}</div>
        </Tooltip>
    )
}

export default ChordFunctionLabel