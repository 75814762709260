import React, { useEffect, useRef, useState } from 'react'
import { Interval, Midi, } from "tonal";
import { FretDisplayMode } from '../../context/settings';
import { AllKey, getChordData, Suffix } from '../../static/chords';
import { getIntervals, getOfficialNote } from '../../static/chord_utils';
import useSettings from '../../hooks/useSettings';

const { ChordBox } = require('vexchords');

function drawChord(ref: any, key: AllKey, suffix: Suffix, variationIndex: number, width: number, fretDisplayMode = FretDisplayMode.NOTES, chordBoxOptions?: any, isLeftHanded = false) {
    let height = width * 1.2;
    const chordBox = new ChordBox(ref.current!, {
        width,
        height,
        showTuning: false,
        bgColor: 'transparent',
        numFrets: 4,
        // See the docs for more available options.
        // https://github.com/0xfe/vexchords
        // fontFamily: 'Helvetica'
        ...chordBoxOptions,
    });
    chordBox.draw(parseChord(key, suffix, variationIndex, fretDisplayMode, isLeftHanded));
    ref.current.querySelector('svg').setAttribute('height', Math.floor(0.85 * height));
}

function parseChord(key: AllKey, suffix: Suffix, variationIndex: number, fretDisplayMode = FretDisplayMode.FINGERING, isLeftHanded = false): any {
    const { frets, fingers, baseFret, midi } = getChordData(key, suffix).positions[variationIndex];
    const chordConfig: any = {};
    chordConfig.position = baseFret;
    const notes = [];
    let midiBuffer = [...midi];
    for (let i = 0; i < 6; i++) {
        const row: [number, string, number?] = [(isLeftHanded ? i : 5 - (i)) + 1, frets[i] === -1 ? 'x' : frets[i].toString()];
        switch (fretDisplayMode) {
            default:
            case FretDisplayMode.NOTES:
                if (frets[i] >= 0) {
                    const note = getOfficialNote(key + suffix, i, frets[i] + baseFret - 1);
                    row.push(note);

                }
                break;
            case FretDisplayMode.INTERVAL:
                if (frets[i] >= 0) {
                    const note = getOfficialNote(key + suffix, i, frets[i] + baseFret - 1);
                    row.push(Interval.distance(key, note));
                }
                break;
            case FretDisplayMode.FINGERING:
                if (frets[i] > 0) {
                    row.push(fingers[i]);
                }
                break;
            case FretDisplayMode.NONE:
                row.push(undefined);
                break;
        }
        notes.push(row);
    }
    chordConfig.chord = notes;
    return chordConfig;
}

function cleanUpChord(ref: any) {
    let svgs = ref.current?.children;
    // remove the first SVG element
    Array.from(svgs || []).forEach((svg: any) => {
        ref.current.removeChild(svg);
    });
}

function ChordDiagram({ tonic, suffix, variationIndex, width, fretDisplayMode, chordBoxOptions = {} }: { tonic: AllKey, suffix: Suffix, variationIndex: number, width: number, fretDisplayMode?: FretDisplayMode, chordBoxOptions?: any }) {
    const [settings] = useSettings();
    const chordRef = useRef<any>(null);
    useEffect(() => {
        drawChord(chordRef, tonic, suffix as Suffix, variationIndex, width, fretDisplayMode || settings.fretDisplayMode, chordBoxOptions, settings.isLeftHanded);
        return () => {
            cleanUpChord(chordRef);
        };
    }, [tonic, suffix, variationIndex, width, chordRef, chordBoxOptions.defaultColor, settings.fretDisplayMode, settings.isLeftHanded]);


    return (
        <div ref={chordRef} style={{ minWidth: `${width}px` }}>
        </div>
    )
}

export default ChordDiagram