import React from 'react'
import { Mode, Chord as TonalChord } from "tonal";
import { AllKey, Suffix, splitChord } from '../../../static/chords';
import ChordButton from './chord_button';
import { ROMAN_NUMERAL_MAP } from '../../../sections/chords_section/chords_section';

function ChordButtonRow({ tonic, mode, selected, onSelect}: { tonic: AllKey, mode: string, selected?: number,  onSelect: (i: number) => void}) {

  return (
      <div className="flex w-full justify-between pt-1 mt-1 overflow-x-auto bg-white dark:bg-slate-800 shadow-md dark:shadow-none dark:[color-scheme:dark]" style={{height: '108px'}}>
          {Mode.triads(mode, tonic).map(splitChord).map(([tonic, suffix], i) => {
              return <ChordButton onClick={() => onSelect(i)} tonic={tonic as AllKey} suffix={suffix as Suffix} title={((`${ROMAN_NUMERAL_MAP[mode][i]}:${tonic}${suffix}`))} selected={selected === i} variationIndex={0} width={80}/>
          })}
      </div>
  );
}

export default ChordButtonRow