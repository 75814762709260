import React from 'react'
import ChordDiagram from '../../chord/chord_diagram'
import { AllKey, Suffix } from '../../../static/chords'
import { FretDisplayMode } from '../../../context/settings'
import './chord_button.css'

function ChordButton({ selected, tonic, suffix, variationIndex, width, title, onClick }: { selected: boolean, tonic: AllKey, suffix: Suffix, variationIndex: number,onClick: () => void, width: number, title: string }) {
  return (
    <div className={`chord-button relative pt-2 pb-1 cursor-pointer bg-white dark:bg-slate-800 hover:brightness-90  dark:hover:brightness-125 rounded-lg h-fit ${selected ? 'active' : ''} mr-2`} onClick={onClick}>
      <div className={`absolute text-xs w-full text-center top-0 ${selected ? 'text-slate-800' : ''}`}>{title}</div>
      <div>
        <ChordDiagram tonic={tonic} suffix={suffix} variationIndex={variationIndex} width={width} fretDisplayMode={FretDisplayMode.NONE} chordBoxOptions={{defaultColor: selected ? '#1e293b': '#666'}}/>
      </div>
    </div>
  )
}

export default ChordButton