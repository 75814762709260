import React, { useEffect, useState, useMemo, useContext } from 'react'
import Chord from '../../components/chord/chord'
import KeyBar from '../../components/key_bar'
import { AllKey, Key, Suffix, doesChordExist } from '../../static/chords'
import FullChordEditor from './full_chord_editor'
import JumboChord from './jumbo_chord'
import { useLocation, useNavigate, UNSAFE_NavigationContext, useParams, Link } from 'react-router-dom'
import { Chord as TonalChord } from 'tonal';
import { useBackListener } from '../../hooks/back_forward_listener'
import { CornerUpLeft, Home } from 'react-feather'
// import { Tooltip } from 'react-tooltip'
import { Helmet } from "react-helmet";
import FretDisplaySelect from '../../components/fret_display_select'
import { deserialize } from '../../components/common/path_utils'


export function parseChord(inputChordPath: string) {
  const chordPath = inputChordPath.replace("slash", "/").replace("sharp", "#");
  const tonics = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"].sort((a, b) => b.length - a.length);

  const tonic = tonics.find(note => chordPath.startsWith(note));
  const suffix = chordPath.split(tonic!)[1];
  return [tonic, suffix];
}
function ExplorerPage() {
  const location = useLocation();
  const { key: paramKey, suffix: paramSuffix } = useParams();

  // const [parsedTonic, parsedSuffix] = parseChord(location.pathname.split("/")[2]);
  const [tonic, setTonic]: [tonic: Key, setTonic: any] = useState((paramKey || 'C') as Key);
  const [suffix, setSuffix]: [suffix: Suffix, setSuffix: any] = useState((paramSuffix || 'major') as Suffix);
  useMemo(() => {
    if (paramKey) {
      setTonic(deserialize(paramKey as string));
    }
    if (paramSuffix) {
      const validatedSuffix = doesChordExist(deserialize(paramKey as string) as Key, deserialize(paramSuffix) as Suffix) ? paramSuffix : 'major';
      setSuffix(deserialize(validatedSuffix as string));
    }
  }, [paramKey, paramSuffix])


  useMemo(() => {
    if (tonic !== paramKey || suffix !== paramSuffix) {
      const chord = `${tonic}${suffix}`.replace('/', 'slash').replace('#', 'sharp');
    }
  }, [tonic, suffix]);

  return (
    <div className='h-screen overflow-y-auto bg-white dark:bg-gray-900 transition-all duration-500 text-stone-500 dark:text-stone-100 flex flex-col items-center pb-5'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {tonic}{suffix} Guitar Chord
        </title>
        <meta name="thumbnail" content={`https://chordoptions.com/images/${paramKey}${paramSuffix}.jpg`} />
        <meta name="description" content={`Look up ${tonic}${deserialize(suffix)} guitar chord and it's variations . Quickly see the notes in ${tonic}${deserialize(suffix)} and the fingering`} />
      </Helmet>
      <img className="hidden" src={`https://chordoptions.com/images/${paramKey}${paramSuffix}.jpg`} alt={`${tonic}${suffix} guitar chord`} />
      <div className='p-3 px-2 flex w-full md:p-1 md:justify-center'>
        <KeyBar
          majMinor='Major'
          suffix={suffix}
          selected={tonic}
          onChange={(newTonic: Key) => {
            if (!doesChordExist(newTonic, suffix)) {
              setSuffix('major');
            }
            setTonic(newTonic)
          }} />
      </div>
      <div className='flex max-sm:flex-col w-full justify-around'>
        <div className='flex flex-col items-center relative'>
          <JumboChord tonic={tonic} suffix={suffix} />
        </div>
        <div className='flex flex-col items-center'>
          <FullChordEditor tonic={tonic} onExtensionChange={(newSuffix: Suffix) => { setSuffix(newSuffix) }} selected={suffix} />
        </div>
      </div>
      <div>
        <ul>
          <li>Click on a note to hear the note played</li>
          <li>Click on arrows to see the chord in different positions on the neck</li>
          <li>Click the dropdown to switch the view between fingerings, note names, or intervals</li>
          <li>Click on the play button to hear the chord</li>
        </ul>
      </div>
      <Link to="/">
        <div className="fixed top-3 right-12 cursor-pointer z-[40] stroke-stone-600 nav-home">
          <Home />
        </div>
      </Link>
      {/* <Tooltip anchorSelect=".nav-home" place="bottom">
        Return Home
      </Tooltip> */}
    </div>

  )
}

export default ExplorerPage