import { CHORD_DICTIONARY } from "../../static/chords";
import { Chord as TonalChord } from "tonal";
import { serialize } from "../common/path_utils";

export const chordList = Object.values(CHORD_DICTIONARY).flat()
    .map(chord => (
        {
            chord: chord.key + chord.suffix,
            aliases: (TonalChord.get(chord.key + chord.suffix)?.aliases || [])
                .map(alias => chord.key + alias),
            key: chord.key,
            suffix: chord.suffix,
        }
    )
    );
