import { useEffect, useState } from 'react';
import '../styles/button.css'

import { Key } from '../static/chords';
import Select from './common/select/select';
import { Link } from 'react-router-dom';
import { serialize } from './common/path_utils';

export const KEYS = ['C', 'C♯', 'D', 'E♭', 'E', 'F', 'F♯', 'G', 'A♭', 'A', 'B♭', 'B'];

function getKey(key: string): Key {
  return key.replace('♭', 'b').replace('♯', '#') as Key;
}

function KeyBar({ majMinor, onChange, selected, suffix }: { majMinor: string, onChange: (key: Key) => void, selected?: Key, suffix?: string }) {
  const [selectedKey, setSelectedKey] = useState(selected?.replace('#', '♯').replace('b', '♭') || KEYS[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    if (selected) {
      setSelectedKey(selected.replace('#', '♯').replace('b', '♭'));
    }
  }, [selected]);
  const handleKeyClick = (key: string) => {
    setSelectedKey(key);
    onChange(getKey(key));
  };

  return (
    <div className="flex items-center max-w-3xl md:w-full justify-center">
      <div className="flex max-md:hidden">
        <div className="font-bold px-4 flex items-center">Key </div>
        {
          (KEYS).map((note, i) => (
            <Link to={`../../${serialize(getKey(note))}/${serialize(suffix ?? majMinor)}`} relative="path">
              <button className={`btn mx-1 text-sm
                              ${selectedKey === note ? 'active' : ''}
                              ${majMinor === 'Major' ? 'btn-sky' : 'btn-emerald'}`}
                onClick={() => handleKeyClick(note)}
              >
                {note}
              </button>
            </Link>)
          )
        }
      </div>

      <div className="md:hidden w-18">
        <Select options={KEYS.map(val => ({ label: val, value: val }))} selectOption={(val) => { handleKeyClick(val.toString()) }} selectedOption={selectedKey} />
      </div>
    </div>
  );
}

export default KeyBar;
