import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { Check as CheckIcon, ChevronDown as ChevronUpDownIcon } from 'react-feather'
import './select.css';

export interface Option {
    label: string;
    value: string|number;
}

export default function Select({options, selectOption, selectedOption}:{options: Option[], selectOption: (value:string|number) => void, selectedOption?: string|number}) {
  const [selected, setSelected] = useState(options[0]);
  useEffect(() => {
    if (selectedOption !== undefined) {
      setSelected(options.filter(option => option.value === selectedOption)[0]);
    }
  }, [selectedOption]);
  return (
    <div className="w-54 top-16">
      <Listbox value={selected} onChange={(val: Option) => {setSelected(val); selectOption(val.value)}}>
        <div className="relative">
          <Listbox.Button className="text-gray-700 font-bold relative w-full cursor-pointer rounded-lg bg-white hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-600 dark:text-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 max-md:text-sm">
            <span className="block truncate">{selected.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="text-left z-30 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 dark:text-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-md:text-sm">
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active }) =>
                    `select-option relative cursor-default dark:text-white select-none py-2 pl-4 pr-4 text-gray-900
                    ${
                      active ? 'active' : ''
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.label}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
