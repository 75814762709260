import { PlayType } from "../../sections/midi_settings/midi_settings_types";

export function playChord(playType: PlayType, ref: any, midi: number[], duration: number, setIsPlaying: any, instrument: number, circles?: HTMLElement[], spacing?: number) {
    if (playType === 'Arpeggiated') {
        arpeggiateChord(ref, midi, duration, setIsPlaying, instrument, circles, spacing);
    } else {
        jazzStrumChord(ref, midi, duration, setIsPlaying, instrument);
    }
}

export function arpeggiateChord(ref: any, midi: number[], duration: number, setIsPlaying: any, instrument: number, circles?: HTMLElement[], spacing?: number) {
    if (midi.length === 0) {
        setTimeout(() => {
            setIsPlaying(false);
        }, duration * 1000);
        return;
    }
    const circle = (circles || [])[0];
    const originalFill = circle.getAttribute('fill');
    circle.style.fill = 'rgb(148 163 184)';

    setTimeout(() => {
        circle.style.fill = originalFill || '#666666';
    }, duration * 1000);
    setIsPlaying(true);
    ref.playChordNow(instrument, [midi[0]], duration);

    setTimeout(() => {
        arpeggiateChord(ref, midi.slice(1), duration, setIsPlaying, instrument, circles?.slice(1), spacing);
    }, spacing ?? 150);
}

export function strumChord(ref: any, midi: number[], duration: number, setIsPlaying: any, instrument: number) {
    ref.playStrumDownNow(instrument, midi, duration);
    setIsPlaying(true);

    setTimeout(() => {
        setIsPlaying(false);
    }, duration * 1000);
}

export function jazzStrumChord(ref: any, midi: number[], duration: number, setIsPlaying: any, instrument: number) {
    setIsPlaying(true);
    ref.playChordNow(instrument, [midi[0]], duration);
    setTimeout(() => {
        ref.playStrumDownNow(instrument, midi, duration);
        setIsPlaying(false);
    }, duration * 750);
}