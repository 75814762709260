import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';


function Toggle({ enabledColor, disabledColor, enabledColorDark, disabledColorDark, handleClick, toggled }: { enabledColor: string, disabledColor: string, enabledColorDark: string, disabledColorDark: string, handleClick: (s?: boolean) => void, toggled?: boolean }) {
  const [enabled, setEnabled] = useState(toggled ?? false);
  useEffect(() => {
    if (toggled !== undefined) {
      setEnabled(toggled);
    }
}, [toggled]);
  function getSwitchClass() {
    const colorClass = `${enabled ? `dark:${enabledColorDark} ${enabledColor}` : `dark:${disabledColorDark} ${disabledColor}`
      }`;
    return `relative inline-flex h-6 w-11 mr-2 items-center rounded-lg shadow-inner transition ${colorClass}`;
  }
  return (
      <Switch
        checked={enabled}
        onChange={(val) => { setEnabled(val); handleClick(val); }}
        className={getSwitchClass()}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${enabled ? 'translate-x-5' : 'translate-x-1'
            } inline-block h-5 w-5 transform rounded-lg bg-white transition shadow-lg border`}
        />
      </Switch>
  )
}

export default Toggle