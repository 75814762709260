import React from 'react'
import { FretDisplayMode } from '../context/settings';
import useSettings from '../hooks/useSettings';
import Select from './common/select/select';

const options = [{ label: "Notes", value: FretDisplayMode.NOTES }, { label: "Interval", value: FretDisplayMode.INTERVAL }, { label: "Fingering", value: FretDisplayMode.FINGERING },];
function FretDisplaySelect() {
    const [settings, saveSettings] = useSettings();
    return (
        <div>
            <Select
                selectedOption={settings.fretDisplayMode ?? 0}
                options={options}
                selectOption={(value) => {
                    saveSettings({
                        ...settings,
                        fretDisplayMode: value as FretDisplayMode
                    })
                }} />
        </div>
    )
}

export default FretDisplaySelect