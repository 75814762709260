import React, { useState } from 'react'

function Slider({ onChange, label, defaultValue = 0, min = 0, max = 100, step = 1 }: { onChange: (s: number) => void, label?: string, defaultValue?: number, min?: number, max?: number, step?:number }) {
    const [val, setValue] = useState(defaultValue || 0);
    return (
        <div className='w-full flex flex-col justify-start my-2'>
            {label && <label className='text-left font-bold text-sm'>{label}</label>}
            <div className='flex items-center'>
                <input onChange={(e) => { setValue(parseInt(e.target.value)); onChange(parseInt(e.target.value)) }} defaultValue={defaultValue} id="default-range" type="range" min={min} max={max} step={step} className="w-5/6 h-2 bg-gray-800 rounded appearance-none cursor-pointer shadow-inset" />
                <div className='w-1/6'> {val}</div>
            </div>
        </div>

    )
}

export default Slider