import React from 'react'
import { AllKey, Chord, CHORD_DICTIONARY, getSupportedKey, Suffix } from '../../static/chords'
import { Chord as TonalChord } from 'tonal';
import { Link } from "react-router-dom";
import { serialize } from '../../components/common/path_utils';

function FullChordEditor({ tonic, onExtensionChange, selected }: ({ tonic: AllKey, onExtensionChange: (extension: Suffix) => void, selected: Suffix })) {

    function generateSection(title: string, filter: Suffix[] = []) {
        const suffixes = findExtensions(tonic, filter);
        if (suffixes.length === 0) {
            return null;
        }
        return (<div>
            <div className='font-bold'>
                {title}
            </div>
            <div className="flex flex-wrap mt-2">
                {suffixes.map((extension, i) => {
                    return (
                        <div>
                            <Link to={`../${serialize(extension)}`} relative="path">
                                <div key={extension}
                                    // onClick={() => { onExtensionChange(extension) }}
                                    className={`editor-btn mr-1 mb-2 
                        ${selected === extension ? 'active' : ''} max-md:text-xs`}>
                                    {extension}
                                </div>
                            </Link>
                            {/* <Link to={`../${serialize(extension)}`} relative="path" style={{ display: 'none' }}>{extension}</Link> */}
                        </div>
                    );
                })}
            </div>
        </div>)
    }

    function findExtensions(key: AllKey, filter: Suffix[] = []) {
        const supportedKey = getSupportedKey(key);
        return (CHORD_DICTIONARY[supportedKey]).map((chord: Chord) => chord.suffix).filter(suff => filter.includes(suff)).map(x => {
            return x;
        });
    }


    return (
        <div className="flex flex-col w-full px-4 h-full overflow-y-auto">
            {generateSection('Major', ['major', '5', '6', '69', 'maj7', 'maj7b5', 'maj7#5', 'maj9', 'maj11', 'maj13', 'add9'])}
            {generateSection('Minor', ['minor', 'm6', 'm69', 'm7', 'm7b5', 'm9', 'm11', 'madd9'])}
            {generateSection('Suspended', ['sus2', 'sus4', 'sus2sus4',])}
            {generateSection('Dominant', ['7', '7sus4', '7/G', '7b5', '7b9', '7#9', '9', '9b5', '9#11', '11', '13',])}
            {generateSection('Diminished', ['dim', 'dim7', 'm7b5'])}
            {generateSection('Augmented', ['aug', 'aug7', 'aug9'])}
            {generateSection('Minor Major', ['mmaj7', 'mmaj7b5', 'mmaj9', 'mmaj11'])}
            {generateSection('Major Slash', ['/E', '/F', '/F#', '/G', '/A', '/Bb', '/B', '/C', '/C#', '/D', '/D#'])}
            {generateSection('Minor Slash', ['m/E', 'm/F', 'm/F#', 'm/G', 'm/B', 'm/C', 'm/C#', 'm/D', 'm/D#'])}
        </div>
    );
}

export default FullChordEditor