import { useEffect, useState } from 'react';
import Toggle from './common/toggle/toggle';
import { Link } from 'react-router-dom';

function KeyToggle({ onChange, selected }: ({ onChange: (majMin: string) => void, selected?: string })) {
    const [selectedKey, setSelectedKey] = useState(selected || 'Major');

    useEffect(() => {
        if (selected) {
            setSelectedKey(selected);
        }
    }, [selected]);
    const handleKeyClick = () => {
        const update = selectedKey === 'Major' ? 'Minor' : 'Major';
        setSelectedKey(update);
        onChange(update);
    };

    return (
        <div className='flex items-center font-bold'>
            <Link className='flex'
                to={`../${selectedKey === 'Major' ? 'Minor' : 'Major'}`} relative="path">
                <Toggle
                    enabledColor='bg-emerald-300'
                    enabledColorDark='bg-emerald-800'
                    disabledColor='bg-sky-300'
                    disabledColorDark='bg-sky-700'
                    handleClick={handleKeyClick}
                    toggled={selectedKey === 'Minor'} />
            </Link>
            {selectedKey}
        </div>
    );
}

export default KeyToggle;
