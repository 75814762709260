import { useEffect, useState } from "react";
import { Sun, Moon } from "react-feather";

export function applyDarkMode() {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.querySelector("#root")?.classList.add('dark')
    } else {
        document.querySelector("#root")?.classList.remove('dark')
    }
}

const DarkModeToggle = ({ theme }: { theme: string }) => {
    const [darkMode, setDarkMode] = useState(theme === "dark");
    useEffect(() => {
        applyDarkMode();
    }, []);
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        window.localStorage.setItem("theme", darkMode ? "light" : "dark");
        applyDarkMode();
    };

    return (
        <div className="cursor-pointer p-3 rounded-full dark:stroke-white hover:bg-gray-200 dark:hover:bg-gray-700" onClick={toggleDarkMode} >
            {
                darkMode ? <Sun  color={"white"} size={36} /> : <Moon size={36} />
            }
        </div>
    );
};

export default DarkModeToggle;