import { AllKey } from "../../static/chords";
import { Key, Note } from "tonal";

const MAX_NUM_FRET = 24;

export const FRETBOARD = generateFretboard();
// const FLAT_SHARP_MAP <Record
export function generateFretboard(key?: AllKey): any {
    const fretboard: Record<number, Array<[string, number]>> = {
        0: [["E", 2]],
        1: [["A", 2]],
        2: [["D", 3]],
        3: [["G", 3]],
        4: [["B", 3]],
        5: [["E", 3]]
    };
    for (let string = 0; string < 6; string++) {
        let prev = fretboard[string][0];
        for (let fret = 1; fret <= 24; fret++) {
            const temp = Note.transpose(prev.join(""), '2m').split(/(\d+)/).slice(0, 2);
            const current = [Note.simplify(temp[0]), parseInt(temp[1])] as any;
            fretboard[string].push(current);
            prev = current;
        }
    }
    return fretboard;
}

export function getNoteOnFretboard(string: number, fret: number) {
    return FRETBOARD[string][fret][0];
}