import React from 'react'
import { HelpCircle } from 'react-feather'
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

function Title({ title, tooltip }: { title: string, tooltip: string }) {
    const id = title.split(' ').join('-').toLowerCase();
    return (
        <div className='flex justify-center items-center'>{title}
            <div className={`${id}`}>

                {/* <HelpCircle size="15" className='cursor-pointer mx-2' /> */}
            </div>
            <Tooltip placement="top" trigger={['click']} overlay={<span>{tooltip}</span>}>
                <HelpCircle size="15" className='cursor-pointer mx-2' />
            </Tooltip>
        </div>
    )
}

export default Title