import React, { useState } from 'react'
import Title from './title/title';
import { ChevronDown, ChevronLeft } from 'react-feather';
import { Mode } from "tonal";
import { titleCase } from '../../components/common/utils';
import Select from './select/select';

const modesOptions = Mode.names().map(mode => ({ label: titleCase(mode), value: mode, }));

function CollapsibleGroup({ title, children, canSelectMode, tooltip }: { title: string, children: any, canSelectMode?: boolean, tooltip: string }) {
  const [isCollapsed, setCollapsed] = useState(false);
  const [mode, setMode] = useState('ionian');

  return (
    <div className='my-5'>
      <div className='flex justify-center w-full items-center'>
        <div className={`cursor-pointer mr-2 ${isCollapsed ? '-rotate-90' : ''}`} onClick={() => { setCollapsed(!isCollapsed) }}>
          <ChevronDown />
        </div>
        <Title title={title} tooltip={tooltip} />
        {canSelectMode && <Select options={modesOptions} selectOption={(mode) => { setMode(mode.toString()) }} />}
      </div>
      <div className={isCollapsed ? 'hidden' : ''}>{React.cloneElement(children, canSelectMode ? { mode } : {})}</div>
    </div>
  )
}

export default CollapsibleGroup;