import React, { useEffect, useState } from "react";
import MidiSettings from "../sections/midi_settings/midi_settings_types";
import Settings from '../sections/settings/settings';
import { Settings as SettingsIcon } from 'react-feather'
import { flattenObject } from "./settings_utils";

export interface Settings {
  fretDisplayMode: FretDisplayMode,
  midi: MidiSettings,
  isLeftHanded: boolean,
}

export enum FretDisplayMode {
  FINGERING,
  NOTES,
  DEGREE,
  INTERVAL,
  NONE,
}

export const defaultSettings: Settings = {
  fretDisplayMode: FretDisplayMode.NOTES,
  midi: {
    playType: 'Arpeggiated',
    instrument: 277,
    duration: 350,
    spacing: 150,
    volume: 7.5,
  },
  isLeftHanded: false,
};

const SettingsContext = React.createContext<[Settings, (values: Settings) => void]>([defaultSettings, () => { }]);

export const SettingsProvider = ({ children, settings }: { children: any, settings: Settings }) => {
  const [isSettingsHidden, setSettingsHidden] = useState(true);
  const [currentSettings, setCurrentSettings] = useState(
    (JSON.parse(localStorage.getItem('settings') || 'null')) || settings
  );

  const saveSettings = (values: Settings) => {
    setCurrentSettings(values);
    localStorage.setItem('settings', JSON.stringify(values));
  };

  return (
    <SettingsContext.Provider
      value={[currentSettings, saveSettings]}
    >
      {children}
      <div className="fixed top-3 right-3 cursor-pointer z-[60] stroke-stone-600">
        <SettingsIcon className="hover:rotate-45 transition  dark:stroke-white" color={isSettingsHidden ? 'currentColor' : 'white'} onClick={() => setSettingsHidden(!isSettingsHidden)} />
      </div>
      <Settings isVisible={!isSettingsHidden} />
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;

