import Chord from '../../components/chord/chord';
import { AllKey, Key, Suffix } from '../../static/chords';
import { KEY_CHORD_MAP } from './key_chords_map';
import { Chord as TonalChord } from "tonal";
import Title from '../../components/common/title/title';
import EmptyChord from '../../components/chord/empty_chord';
import { ROMAN_NUMERAL_MAP } from './chords_section';
import { useMediaQuery } from 'react-responsive'

function SecondaryChordSection({ chords, title, mode, isSecondary, degree }: { chords: string[], title: string, mode: string, isSecondary?: boolean, degree?: number }) {
    // chords.filter(x => x !== '').forEach(x => console.log(TonalChord.get(x)));
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    if (degree !== undefined && chords[degree] === '' && !isMd) {
        return null;
    }
    return (
        <div className="flex w-full max-md:flex-col justify-between py-4">
            {chords.map((chord: string, i: number) => {
                if (chord === '') {
                    return <EmptyChord />
                }
                const chordData = TonalChord.get(chord);
                return <Chord chordKey={chordData.tonic! as AllKey} initialSuffix={chord.split(chordData.tonic!)[1] as Suffix} isDominant header={isSecondary ? `V/${ROMAN_NUMERAL_MAP[mode][i]}` : ''} />
            }).filter((_, i) => degree === undefined || isMd || i === degree)}
        </div>
    );
}

export default SecondaryChordSection;
