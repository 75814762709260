import Toggle from '../../components/common/toggle/toggle';
import useSettings from '../../hooks/useSettings';

function PlayTypeToggle() {
    const [settings, saveSettings] = useSettings();

    const handleKeyClick = () => {
        const update = settings.midi.playType === 'Arpeggiated' ? 'Strummed' : 'Arpeggiated';
        saveSettings({
            ...settings,
            midi: {
                ...settings.midi,
                playType: update
            }
        })
    };

    return (
        <div className="flex align-center font-bold text-white">
            <Toggle enabledColor='bg-gray-700' enabledColorDark='bg-gray-700' disabledColor='bg-gray-800' disabledColorDark='bg-gray-800' handleClick={handleKeyClick} toggled={settings?.midi.playType === 'Strummed' ?? false} />
            {settings.midi.playType === 'Arpeggiated' ? 'Arpeggiated' : 'Root + Chord'}
        </div>
    );
}

export default PlayTypeToggle;
