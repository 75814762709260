import { useContext, useState } from 'react';
import { Play, Pause } from "react-feather";
import { MidiContext } from '../../context/midi';
import useSettings from '../../hooks/useSettings';
import { strumChord, arpeggiateChord, playChord } from './midi_utils';

const { default: MIDISounds } = require('midi-sounds-react');

export interface MidiPlayerConfig {
    midi: number[],
}

function MidiPlayer({ config, chordRef, size }: { config: MidiPlayerConfig, chordRef?: any, size?: number }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [settings] = useSettings();
    const midiRef = useContext(MidiContext);
    let originalFills = [];
    if (chordRef.current) {
        setTimeout(() => {
            const text: any[] = Array.from(chordRef.current.querySelectorAll("text"));
            text.forEach(text => {
                text.style.cursor = "pointer";
                text.style.userSelect = "none";
                text.style.pointerEvents = "none";
            });
            const circles: any[] = Array.from(chordRef.current.querySelectorAll("circle"));
            circles.forEach((circle, i) => {
                circle.style.cursor = "pointer";
                circle.addEventListener('click', () => {
                    if (midiRef) {
                        (midiRef as any).cancelQueue();
                        (midiRef as any).playChordNow(settings.midi.instrument, [config.midi[i]], settings.midi.duration / 1000)
                    }
                });
                const originalFill = circle.getAttribute('fill');
                // Add the "mouseenter" event listener
                circle.addEventListener('mouseenter', () => {
                    circle.style.fill = '#aaa';
                });

                // Add the "mouseleave" event listener
                circle.addEventListener('mouseleave', () => {
                    circle.style.fill = originalFill;
                });
            });
        });
    }
    return (
        <div>
            <div className='cursor-pointer'>
                {isPlaying ? <Pause size={size || 16} /> : <Play size={size || 16} onClick={() => {
                    const circles: any[] = Array.from(chordRef.current.querySelectorAll("circle"));
                    playChord(settings.midi.playType, midiRef, config.midi, settings.midi.duration / 1000, setIsPlaying, settings.midi.instrument, [...circles], settings.midi.spacing)
                }} />}
            </div>
        </div>
    );
}

export default MidiPlayer;
