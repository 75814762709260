import { useEffect, useRef } from 'react';
import Chord from '../../components/chord/chord';
import { AllKey, Key, splitChord, Suffix } from '../../static/chords';
import { KEY_CHORD_MAP } from './key_chords_map';
import { Mode, Chord as TonalChord } from "tonal";
import { useMediaQuery } from 'react-responsive'

export const ROMAN_NUMERAL_MAP: any = {
    'ionian': ['I', 'ii', 'iii', 'IV', 'V', 'vi', 'vii°'],
    'dorian': ['i', 'ii', '♭III', 'IV', 'v', 'vi°', '♭VII'],
    'phrygian': ['i', '♭II', '♭III', 'iv', 'v°', '♭VI', '♭vii'],
    'lydian': ['I', 'II', 'iii', '♯iv°', 'V', 'vi', 'vii'],
    'mixolydian': ['I', 'ii', 'iii°', 'IV', 'v', 'vi', '♭VII'],
    'aeolian': ['i', 'ii°', '♭III', 'iv', 'v', '♭VI', '♭VII'],
    'locrian': ['i°', '♭II', '♭iii', 'iv', '♭V', '♭VI', '♭vii'],
}
function isDominant(inputChord: string) {
    return TonalChord.get(inputChord).type.includes("dominant");
}
function ChordsSection({ tonic, mode, degree }: { tonic: AllKey, mode: string, degree?: number }) {
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })
    return (
        <div className="flex w-full justify-between max-md:flex-col pt-4">
            {Mode.triads(mode, tonic).map(splitChord).map(([tonic, suffix], i) => {
                return <Chord chordKey={tonic as AllKey} initialSuffix={suffix as Suffix} isDominant={isDominant(Mode.seventhChords(mode, tonic)[i])} header={(ROMAN_NUMERAL_MAP[mode][i])} chordFunction={['ionian', 'aeolian'].includes(mode) ? i + 1 : undefined} />
            }).filter((_, i) => degree === undefined || isMd || i === degree)}
        </div>
    );
}

export default ChordsSection;
