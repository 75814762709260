import './App.css';
import KeyBar from './components/key_bar'
import KeyToggle from './components/key_toggle';
import { useState, useEffect, createContext } from "react";
import DarkModeToggle, { applyDarkMode } from "./components/dark_mode_toggle";
import Chord from './components/chord/chord';
import ChordsSection from './sections/chords_section/chords_section';
import ChordsEditor from './sections/chords_editor/chords_editor';
import { AllKey, Key, Suffix } from './static/chords';
import MidiSettings from './sections/midi_settings/midi_settings';
import Settings from './sections/settings/settings';
import { Settings as SettingsIcon, Compass as CompassIcon, Search as SearchIcon } from 'react-feather'
import { Key as TonalKey, Note, Interval } from "tonal";
import SecondaryChordSection from './sections/chords_section/secondary_dominant_section';
import BorrowedChordSection from './sections/chords_section/borrowed_chord_sections';
import Toggle from './components/common/toggle/toggle';
import _ from "lodash";
import Select from './components/common/select/select';
import { titleCase } from './components/common/utils';
import FretDisplaySelect from './components/fret_display_select';
import { HelpCircle } from 'react-feather';
import Title from './components/common/title/title';
import Search from './components/search/search';
import DegreeBar from './components/degree_bar';
import ChordButtonRow from './components/common/chord_button_row/chord_button_row';
import CollapsibleGroup from './components/common/collapsible_group';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deserialize, serialize } from './components/common/path_utils';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

function App() {
  const [theme, setTheme] = useState("light");
  let { key: paramKey, majmin: paramMajMin } = useParams();
  const [degree, setDegree] = useState(0);
  const [majMinor, setMajMinor] = useState(paramMajMin || "Major");
  const [isSearchHidden, setSearchHidden] = useState(true);
  const [key, setKey] = useState(paramKey ? deserialize(paramKey) as Key : "C" as Key);
  const [isSettingsHidden, setSettingsHidden] = useState(true);
  const [majorKeyData, setMajorKeyData]: any = useState(TonalKey.majorKey(key));
  const [minorKeyData, setMinorKeyData]: any = useState(TonalKey.minorKey(key));
  const [activeSection, setActiveSection] = useState('diatonic');
  const sectionIds = ['diatonic', 'secondary', 'substitute', 'borrowed'];
  const navigate = useNavigate();
  ReactGA.initialize("G-6JFLDK13TG");

  useEffect(() => {
    if (paramKey && paramKey !== key) {
      setKey(deserialize(paramKey) as Key);
    }
    if (paramMajMin && paramMajMin !== majMinor) {
      setMajMinor(paramMajMin as Suffix);
    }
  }, [paramKey, paramMajMin]);

  function getTritoneSubstitutionChords(tonic: Key) {
    if (majMinor === "Major") {
      return [1, 2, 3, 4, 5, 6, 7].map(i => {
        if ([1, 2, 3, 4, 5, 6].includes(i)) {
          return Note.simplify(Note.transpose(majorKeyData.scale[i - 1], '2m')) + "7";
        } else {
          return '';
        }
      });
    } else {
      return [1, 2, 3, 4, 5, 6, 7].map(i => {
        if ([1, 3, 4, 5, 6, 7].includes(i)) {
          return Note.simplify(Note.transpose(minorKeyData.natural.scale[i - 1], '2m')) + "7";
        } else {
          return '';
        }
      });
    }
  }
  function getSecondaryDominantChords(tonic: Key) {
    if (majMinor === "Major") {
      return majorKeyData.secondaryDominants;
    } else {
      return [1, 2, 3, 4, 5, 6, 7].map(i => {
        if ([3, 4, 5, 6, 7].includes(i)) {
          return getSecondaryDominant(minorKeyData.natural.scale[i - 1]) + "7";
        } else {
          return '';
        }
      })
    }
  }
  function getSecondaryDominant(tonic: AllKey) {
    return TonalKey.majorKey(tonic).scale[4];
  }

  useEffect(() => {
    const onScroll = (e: any) => {
      sectionIds.forEach(id => {
        const rect = document.getElementById(id)?.getBoundingClientRect();
        if (rect !== undefined && rect?.top <= 100 && rect?.bottom > 0) {
          setActiveSection(id);
        }
      })
    };
    const throttledOnScroll = _.throttle(onScroll, 500);
    window.addEventListener("scroll", throttledOnScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (majMinor === "Major") {
      //console.log(TonalKey.majorKey(key));
      setMajorKeyData(TonalKey.majorKey(key));
    } else {
      //console.log(TonalKey.minorKey(key));
      setMinorKeyData(TonalKey.minorKey(key));
    }
    navigate(`/key/${serialize(key)}/${majMinor}`);
  }, [key, majMinor]);

  return (
    <div className="App">
      <header className="App-header bg-white dark:bg-gray-900 transition-all duration-500 text-stone-500 dark:text-stone-100 h-screen max-md:overflow-hidden flex-col">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Chords in {key} {majMinor}
          </title>
          <meta name="description" content={`Explore the different guitar chords in the key of ${key}${majMinor}. See different chord options, including secondary dominant, tritone substitution and borrowed chords. Use our interactive chords to look up chord notes, fingerings and more.`} />
        </Helmet>
        {!isSearchHidden && <Search onClose={() => { setSearchHidden(true) }} />}

        <div className="shadow fixed top-0 max-w-3xl w-full py-1 px-3 flex flex-wrap items-center max-md:justify-start z-50 bg-slate-50 dark:bg-slate-700 max-md:rounded-none rounded-b-lg transition-color duration-500 [&>*]:mr-2">
          {/* <div className="md:hidden">
            <Select options={sectionIds.map(id => ({ label: titleCase(id), value: id }))} selectedOption={activeSection} selectOption={(value) => {
              document.getElementById(value.toString())?.scrollIntoView({ behavior: 'smooth' });
            }} />
          </div> */}
          <KeyBar majMinor={majMinor} onChange={(key: Key) => { setKey(key) }} selected={key} />
          <KeyToggle onChange={(majMinor: string) => { setMajMinor(majMinor) }} selected={majMinor} />
          <FretDisplaySelect />
        </div>
        <div className="fixed top-3 right-12 cursor-pointer z-50 stroke-white">
          <SearchIcon onClick={() => setSearchHidden(false)} />
          <Link to={`../chord/C/major`} style={{ display: 'none' }}>C Major</Link>
        </div>
        <div className='fixed top-10 z-40 w-screen md:hidden'>
          <ChordButtonRow selected={degree} onSelect={(i) => { setDegree(i) }} tonic={key} mode={majMinor === "Major" ? 'ionian' : 'aeolian'} />
          {/* <DegreeBar onChange={(i) => { setDegree(i) }} majMinor={majMinor}/> */}
        </div>
        <div className="md:pt-24 max-md:mt-40 w-full flex-grow overflow-auto dark:[color-scheme:dark] pb-40">
          <CollapsibleGroup title={"DIATONIC"} tooltip='You can choose to replace a chord with another chord in the same key with the same chord function. Or alternatively switch things up with the relative major or minor.'>
            <ChordsSection tonic={key} mode={majMinor === "Major" ? 'ionian' : 'aeolian'} degree={degree} />
          </CollapsibleGroup>
          <CollapsibleGroup title={"SECONDARY DOMINANT"} tooltip='A secondary dominant is a dominant chord that resolves to a chord other than the tonic chord.'>
            <SecondaryChordSection chords={getSecondaryDominantChords(key)} title={"SECONDARY DOMINANT"} mode={majMinor === "Major" ? 'ionian' : 'aeolian'} degree={degree} isSecondary />
          </CollapsibleGroup>
          <CollapsibleGroup title={"TRITONE SUBSTITUTION"} tooltip='A tritone substitution is a chord substitution in which a dominant seventh chord is replaced by another dominant seventh chord that is a tritone away. For example, try Db9 => Cmaj7'>
            <SecondaryChordSection chords={getTritoneSubstitutionChords(key)} title={"TRITONE SUBSTITUTION"} mode={majMinor === "Major" ? 'ionian' : 'aeolian'} degree={degree} />
          </CollapsibleGroup>
          <CollapsibleGroup title={"BORROWED"} tooltip="A Borrowed Chord is a chord that is 'borrowed' from a parallel mode from your song's key" canSelectMode>
            <BorrowedChordSection tonic={key} degree={degree} mode={"ionian"} />
          </CollapsibleGroup>
        </div>
      </header>
    </div>
  );
}

export default App;
