import React, { useEffect, useState, useMemo, useRef } from 'react'
import ChordDiagram from '../../components/chord/chord_diagram'
import _ from 'lodash';
import { AllKey, Suffix, doesChordExist, getChordData } from '../../static/chords';
import { ChevronLeft, ChevronRight, Play } from 'react-feather'
import MidiPlayer from '../../components/midi_player/midi_player';
import FretDisplaySelect from '../../components/fret_display_select';
function getWidth() {
    if (window.innerWidth < 640) {
        return window.innerWidth * 0.75;
    }
    return Math.min(window.innerHeight * 0.75, window.innerWidth / 2);
}

function JumboChord({ tonic, suffix }: { tonic: AllKey, suffix: Suffix }) {
    const [width, setWidth] = useState(getWidth());
    const [variationIndex, setVariationIndex] = useState(0);
    const [chord, setChord] = useState(getChordData(tonic, suffix).positions[0]);
    useMemo(() => {
        setVariationIndex(0);
        setChord(getChordData(tonic, suffix).positions[0]);
    }, [tonic, suffix]);
    useMemo(() => {
        setChord(getChordData(tonic, suffix).positions[variationIndex]);
    }, [variationIndex]);
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            setWidth(Math.round(getWidth()));
        }
        const throttledResize = _.throttle(handleResize, 350);
        // Add event listener
        window.addEventListener("resize", throttledResize);
        // Call handler right away so state gets updated with initial window size
        throttledResize();
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", throttledResize)
        };
    }, []);
    const containerRef = useRef<any>(null);

    function handleVariationChange(diff: number) { setVariationIndex(Math.max(Math.min(variationIndex + diff, getChordData(tonic, suffix as Suffix).positions.length - 1), 0)) }

    return (
        <div className='relative' ref={containerRef}>
            <div style={{ top: '10%' }} className='absolute text-4xl max-md:text-xl font-bold left-1/2 transform -translate-x-1/2 -translate-y-1/2'>{`${tonic}${suffix}`}</div>
            <ChordDiagram tonic={tonic} suffix={suffix} variationIndex={variationIndex} width={width} />
            <div className={`absolute w-5 h-full left-0 top-0 cursor-pointer flex flex-col justify-center`}>
                <ChevronLeft className="h-1/3 rounded" onClick={() => { handleVariationChange(-1) }} />
            </div>
            <div className={`absolute w-5 h-full right-0 top-0 cursor-pointer flex flex-col justify-center`}>
                <ChevronRight className={`h-1/3 rounded`} onClick={() => { handleVariationChange(1) }} />
            </div>
            <div className='absolute z-50 top-0 left-0'>

                <MidiPlayer config={{ midi: chord.midi }} chordRef={containerRef} size={24} />
            </div>
            <div className='absolute top-0 right-0'>
                <FretDisplaySelect />
            </div>
            {/* <div className='absolute bottom-5 px-8 py-4 rounded-lg hover:brightness-110 stroke-white fill-white bg-gradient-to-br from-sky-400 to-rose-500 cursor-pointer left-1/2 transform -translate-x-1/2'><Play color='white'/></div> */}
        </div>
    )
}

export default JumboChord