import { createContext, useState } from "react";

export const  MidiContext = createContext(null)
const { default: MIDISounds } = require('midi-sounds-react');

export const MidiProvider = ({ children }: { children: any }) => {
  const [value, setValue] = useState(null);

  return (
    <MidiContext.Provider value={value}>
      {children}
      <div className="hidden">
        <MIDISounds ref={(ref: any) => { setValue(ref) }} appElementName="root" instruments={[277]} />
      </div>
    </MidiContext.Provider>
  );
};
