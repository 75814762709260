import React, { useEffect, useState } from 'react'
import { getChordContainerWidth } from './chord';
import _ from "lodash";

function EmptyChord() {
    const [maxWidth, setMaxWidth] = useState(150);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            setMaxWidth(getChordContainerWidth(window.innerWidth));
        }
        const throttledResize = _.throttle(handleResize, 350);
        // Add event listener
        window.addEventListener("resize", throttledResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", handleResize)
        };
    }, []);
    return (
        <div style={{ width: maxWidth, height: 1 }}></div>
    )
}

export default EmptyChord