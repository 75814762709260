import { getNoteOnFretboard } from "../components/chord/fretboard";
import { Chord, Note, Interval } from "tonal";

export function getOfficialNote(chordName: string, string: number, fret: number) {
    const chordNoteNamesMap = Chord.get(chordName).notes.reduce((acc, curr) => {
        const midi = Note.get(Note.simplify(curr)+ "2").freq;
        acc[midi!] = curr;
        return acc;
    }, {} as any);

    return chordNoteNamesMap[Note.get(getNoteOnFretboard(string, fret) + "2").freq!] || getNoteOnFretboard(string, fret);
}

export function getIntervals(chordName: string, string: number, fret: number) {
    const chordNotes = getOfficialNote(chordName, string, fret);
    const chord = Chord.get(chordName);
}