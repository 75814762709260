import { useState, useEffect, useRef, useMemo } from 'react';
import _ from "lodash";
import { CHORD_DICTIONARY, Suffix, Key, AllKey, Chord as ChordData, getSupportedKey, getChordData } from '../../static/chords';
import ChordEditor from './chord_editor';
import { Play, Pause } from "react-feather";
import MidiPlayer from '../midi_player/midi_player';
import { RefreshCw, ChevronLeft, ChevronRight } from "react-feather";
import { Interval, Midi, Chord as TonalChord } from "tonal";
import { FretDisplayMode } from '../../context/settings';
import useSettings from '../../hooks/useSettings';
import { generateFretboard } from './fretboard';
import { getOfficialNote } from '../../static/chord_utils';
import ChordFunctionLabel from '../common/chord-function-label';

const { ChordBox } = require('vexchords');

function cleanUpChord(ref: any) {
    let svgs = ref.current?.children;
    // remove the first SVG element
    Array.from(svgs || []).forEach((svg: any) => {
        ref.current.removeChild(svg);
    });
}

export function getChordContainerWidth(windowWidth: number) {
    if (windowWidth < 768) {
        return windowWidth / 2;
    }
    return Math.max(Math.min(windowWidth, 2180) / 7 - 15, 100)
}

function drawChord(ref: any, key: AllKey, suffix: Suffix, variationIndex: number, fretDisplayMode = FretDisplayMode.FINGERING, isLeftHanded = false) {
    if (!ref.current) {
        return;
    }
    let width = getChordContainerWidth(window.innerWidth);
    let height = width * 1.2;
    const chordBox = new ChordBox(ref.current!, {
        width,
        height,
        showTuning: false,
        numFrets: 4,
        bgColor: 'transparent',
        // See the docs for more available options.
        // https://github.com/0xfe/vexchords
        // fontFamily: 'Helvetica'
    });
    chordBox.draw(parseChord(key, suffix, variationIndex, fretDisplayMode, isLeftHanded));
    ref.current.querySelector('svg').setAttribute('height', Math.floor(0.85 * height));
}

function parseChord(key: AllKey, suffix: Suffix, variationIndex: number, fretDisplayMode = FretDisplayMode.FINGERING, isLeftHanded = false): any {
    const { frets, fingers, baseFret, midi } = getChordData(key, suffix).positions[variationIndex];
    const chordConfig: any = {};
    chordConfig.position = baseFret;
    const notes = [];
    let midiBuffer = [...midi];
    for (let i = 0; i < 6; i++) {
        const row: [number, string, number?] = [(isLeftHanded ? i : 5 - (i)) + 1, frets[i] === -1 ? 'x' : frets[i].toString()];
        switch (fretDisplayMode) {
            default:
            case FretDisplayMode.NOTES:
                if (frets[i] >= 0) {
                    row.push(getOfficialNote(key + suffix, i, frets[i] + baseFret - 1));
                }
                break;
            case FretDisplayMode.INTERVAL:
                if (frets[i] >= 0) {
                    const note = getOfficialNote(key + suffix, i, frets[i] + baseFret - 1);
                    row.push(Interval.distance(key, note));
                }
                break;
            case FretDisplayMode.FINGERING:
                if (frets[i] > 0) {
                    row.push(fingers[i]);
                }
                break;
        }
        notes.push(row);
    }
    chordConfig.chord = notes;
    return chordConfig;
}

function Chord({ chordKey, initialSuffix, isDominant, header, chordFunction }: { chordKey: AllKey, initialSuffix: Suffix, isDominant?: boolean, header?: string, chordFunction?: number }) {

    const [isHovered, setIsHovered] = useState(false);
    const [variationIndex, setVariationIndex] = useState(0);
    const [maxWidth, setMaxWidth] = useState(150);
    const [suffix, setSuffix] = useState(initialSuffix);
    const [chord, setChord] = useState(getChordData(chordKey, initialSuffix).positions[0]);
    const [settings] = useSettings();

    const chordRef = useRef<any>(null);

    function reset() {
        setVariationIndex(0);
        setSuffix(initialSuffix);
    }

    function handleVariationChange(diff: number) { setVariationIndex(Math.max(Math.min(variationIndex + diff, getChordData(chordKey, suffix as Suffix).positions.length - 1), 0)) }

    useMemo(() => {
        setVariationIndex(0);
        setSuffix(initialSuffix);
        cleanUpChord(chordRef);
        drawChord(chordRef, chordKey, suffix as Suffix, variationIndex, settings.fretDisplayMode, settings.isLeftHanded);
        setChord(getChordData(chordKey, initialSuffix).positions[0]);
        return () => {
            cleanUpChord(chordRef);
        };
    }, [chordKey, initialSuffix]);

    useMemo(() => {
        setVariationIndex(0);
        cleanUpChord(chordRef);
        drawChord(chordRef, chordKey, suffix as Suffix, variationIndex, settings.fretDisplayMode, settings.isLeftHanded);
        setChord(getChordData(chordKey, suffix).positions[variationIndex]);

        return () => {
            cleanUpChord(chordRef);
        };
    }, [suffix]);

    useEffect(() => {
        cleanUpChord(chordRef);
        drawChord(chordRef, chordKey, suffix as Suffix, variationIndex, settings.fretDisplayMode, settings.isLeftHanded);

        return () => {
            cleanUpChord(chordRef);
        };
    }, [settings]);

    useEffect(() => {
        cleanUpChord(chordRef);
        drawChord(chordRef, chordKey, suffix as Suffix, variationIndex, settings.fretDisplayMode, settings.isLeftHanded);
        setChord(getChordData(chordKey, suffix).positions[variationIndex]);

        return () => {
            cleanUpChord(chordRef);
        };
    }, [variationIndex]);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            setMaxWidth(getChordContainerWidth(window.innerWidth));
            cleanUpChord(chordRef);
            drawChord(chordRef, chordKey, suffix as Suffix, variationIndex, settings.fretDisplayMode);
        }
        const throttledResize = _.throttle(handleResize, 350);
        // Add event listener
        window.addEventListener("resize", throttledResize);
        // Call handler right away so state gets updated with initial window size
        throttledResize();
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", throttledResize)
            cleanUpChord(chordRef);
        };
    }, []); // Empty array ensures that effect is only run on mount

    return (
        <div className="chord-diagram rounded md:hover:bg-gray-100 md:dark:hover:bg-gray-800  mx-1 relative max-md:flex pb-3 pt-1"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div className="relative" style={{ maxWidth: maxWidth }}>
                {header && <div className="h-5 flex justify-center items-center">{chordFunction && <ChordFunctionLabel degree={chordFunction} />}{header}</div>}
                <div className={`absolute top-${header ? '6' : '1'} w-full text-center font-bold lg:text-base md:text-xs`}> {chordKey}{suffix}</div>
                <div className="relative">
                    <div ref={chordRef}>
                    </div>
                    <div className={`absolute w-5 h-full left-0 top-0 cursor-pointer flex flex-col justify-center ${(isHovered) ? '' : 'hidden'}`}>
                        <ChevronLeft className="h-1/3 rounded" onClick={() => { handleVariationChange(-1) }} />
                    </div>
                    <div className={`absolute w-5 h-full right-0 top-0 cursor-pointer flex flex-col justify-center ${(isHovered) ? '' : 'hidden'}`}>
                        <ChevronRight className={`h-1/3 rounded`} onClick={() => { handleVariationChange(1) }} />
                    </div>
                </div>
                <div className={`absolute top-2 left-2 ${(isHovered || window.innerWidth < 768) ? '' : 'hidden'}`} >
                    <MidiPlayer config={{ midi: chord.midi }} chordRef={chordRef} />
                </div>
                <div className={'rounded-full bg-white-800'}>
                    <RefreshCw size="14" onClick={() => { reset() }} className={`absolute cursor-pointer top-2 right-2 ${(isHovered) ? '' : 'hidden'}`} />
                </div>
            </div>
            {(isHovered || window.innerWidth < 768) &&
                <div className="absolute rounded bg-gray-100 dark:bg-gray-800 max-md:right-0 max-md:bg-transparent dark:max-md:bg-transparent" style={{ width: maxWidth, zIndex: 20 }}>
                    <ChordEditor chordKey={chordKey}
                        selected={suffix}
                        onExtensionChange={(ext: string) => setSuffix(ext as Suffix)}
                        initialSuffix={initialSuffix}
                        isDominant={isDominant} /> </div>}
        </div>
    );
}

export default Chord;
