import Toggle from '../../components/common/toggle/toggle';
import useSettings from '../../hooks/useSettings';
import MidiSettings from '../midi_settings/midi_settings'

export function Settings({ isVisible }: { isVisible: boolean }) {
    const [settings, saveSettings] = useSettings();

    function getContainerStyle() {
        const sharedStyle = "z-50 w-72 h-full bg-slate-500 p-3 transition-all duration-300 fixed top-0 right-0 text-white";
        if (isVisible) {
            return sharedStyle
        }
        return `${sharedStyle} translate-x-72`
    }
    return (
        <div className={getContainerStyle()}>
            <MidiSettings />
            <div className="w-full">
                <h2 className="font-bold text-lg text-left">Layout</h2>
                <div className='my-2'>
                    <div className="flex align-center font-bold text-white">
                        <Toggle enabledColor='bg-green-600' enabledColorDark='bg-emerald-600' disabledColor='bg-gray-800' disabledColorDark='bg-gray-800' handleClick={() => {
                            saveSettings({
                                ...settings,
                                isLeftHanded: !settings.isLeftHanded
                            })
                        }} toggled={settings?.isLeftHanded ?? false} />
                        Left Handed Mode
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Settings