import { useState } from 'react';
import { RefreshCw, ChevronLeft, ChevronRight, MoreHorizontal } from "react-feather";
import { AllKey, CHORD_DICTIONARY, getSupportedKey, Suffix, Chord, SUFFIX_ORDER_MAP } from '../../static/chords';
import './chord_editor.css';

//TODO FIX handle case by case major minor dim
function findExtensions(key: AllKey, suffix: Suffix, isDominant = false) {
    const supportedKey = getSupportedKey(key);
    const suffixes = (CHORD_DICTIONARY[supportedKey]).map((chord: Chord) => chord.suffix).filter((suff: Suffix) => {
        let suffixes: Suffix[] = []
        if (suffix === 'major') {
            suffixes = ['major', 'minor', 'sus2', 'sus4', 'add9', '6', '69', 'maj7', 'maj9', 'maj11', 'maj13'];
        }
        if (suffix === 'minor') {
            suffixes = ['minor', 'major', 'sus2', 'sus4', 'madd9', 'm6', 'm69', 'm7','m7b5', 'm9', 'm11'];
        }
        if (suffix === 'dim') {
            suffixes = ['dim', 'dim7', 'm7b5'];

        }
        const domSuffixes = ['7', '7b5', '7b9', '9', '9b5', '11', '13'] as Suffix[];
        if (isDominant) {
            suffixes.push(...domSuffixes);
        }
        return suffixes.includes(suff);
    });
    suffixes.sort((a, b) => SUFFIX_ORDER_MAP[a] - SUFFIX_ORDER_MAP[b]);
    return suffixes;
}

function ChordEditor({ chordKey, initialSuffix, onExtensionChange, selected, isDominant }: ({ chordKey: AllKey, initialSuffix: Suffix, onExtensionChange: (extension: Suffix) => void, selected: Suffix, isDominant?: boolean })) {
    const [suffix, setSuffix] = useState(initialSuffix);

    const handleKeyClick = () => {
    };

    return (
        <div className="flex flex-col w-full px-4">
            <div className="flex flex-wrap mt-2">
                {findExtensions(chordKey, initialSuffix, isDominant).map((extension, i) => {
                    return (<div key={extension} onClick={() => { onExtensionChange(extension) }} className={`editor-btn mr-1 mb-2 text-xs max-md:text-base ${selected === extension ? 'active' : ''}`}>{extension}</div>);
                })}
                {/* <div className="editor-btn text-white mr-1 mb-2 text-xs max-md:text-base bg-gradient-to-br from-sky-400 to-emerald-400 dark:from-sky-600 dark:to-emerald-600 hover:brightness-110 transition flex items-center"><MoreHorizontal size={16}/></div> */}
            </div>
        </div>
    );
}

export default ChordEditor;
