import React, { createContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { defaultSettings, SettingsProvider } from './context/settings';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ExplorerPage from './pages/explorer/explorer';
import { MidiProvider } from './context/midi';
import DarkModeToggle from './components/dark_mode_toggle';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/key/:key/:majmin",
    element: <App />,
  },
  {
    path: "/chord/:key/:suffix",
    element: <ExplorerPage />,
  }
]);

const rootElement = document.getElementById("root") as any;
let midiRef = null;
const app = (<React.StrictMode>
  <MidiProvider>
    <SettingsProvider settings={defaultSettings}>
      <RouterProvider router={router} />
      <div className="fixed left-5 bottom-5 z-20">
        <DarkModeToggle theme={window.localStorage.getItem("theme") || "light"} />
      </div>
    </SettingsProvider>
  </MidiProvider>
</React.StrictMode>);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, app);
}
const root = ReactDOM.createRoot(rootElement as any);

root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
