import React, { useMemo, useState } from 'react'
import { Search as SearchIcon, X as XIcon } from 'react-feather';
import Fuse from 'fuse.js'
import { chordList } from './chord_list';
import Autosuggest from 'react-autosuggest';
import './search.css';
import { useNavigate } from 'react-router-dom';
import { serialize } from '../common/path_utils';

function Search({ onClose }: { onClose: () => void }) {
    const [result, setResult] = useState([]);
    const [value, setValue] = useState('');
    const navigate = useNavigate();

    const fuse = new Fuse(chordList, {
        includeScore: true,
        keys: ['chord', 'aliases']
    })

    const onChange = (event: any, { newValue }: { newValue: string, }) => {
        setValue(newValue);
        // navigate(`/chord/${serialize(newValue)}`);
    };

    const onSuggestionSelected = (event: any, suggestion: any) => {
        const suggestionObj = suggestion.suggestion.item
        navigate(`/chord/${serialize(suggestionObj.key)}/${serialize(suggestionObj.suffix)}`);

    }

    const inputProps = {
        placeholder: "Type in a Chord",
        value,
        onChange,
        autoFocus: true,
    };

    return (
        <div className='fixed h-full w-full top-0 left-0 bg-slate-500 z-[70] bg-opacity-50 backdrop-blur'>
            <div className='pointer'>
                <XIcon className="cursor-pointer" onClick={onClose} />
            </div>
            <Autosuggest
                suggestions={result}
                onSuggestionSelected={onSuggestionSelected}
                // onSuggestionSelected={(value) => {
                //     console.log(value);
                //     navigate(`/chord/${serialize((value.target as any).value)}`)
                // }}
                onSuggestionsFetchRequested={({ value }) => {
                    setResult(((fuse.search(value)).filter(result => (result.score ?? 0) < 0.00001) as any).slice(0, 9));
                }}
                onSuggestionsClearRequested={() => { setResult([]); }}
                getSuggestionValue={((result: any) => result.item.chord)}
                renderSuggestion={(result: any) => result.item.chord}
                inputProps={inputProps} />
        </div>
    )
}

export default Search