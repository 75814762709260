import React, { useContext } from 'react'
import Select from '../../components/common/select/select'
import useSettings from '../../hooks/useSettings';
import { instrumentOptions } from './instruments'
import PlayTypeToggle from './play_type_toggle'
import { MidiContext } from '../../context/midi';
import Slider from '../../components/common/slider/slider';
import { debounce } from 'lodash';
function MidiSettings() {
    const [settings, saveSettings] = useSettings();
    const midiRef = useContext(MidiContext);

    const handleInstrumentUpdate = (instrument: string | number) => {
        const casted = instrument as number;
        (midiRef as any).cacheInstrument(instrument);
        saveSettings({
            ...settings,
            midi: {
                ...settings.midi,
                instrument: casted,
            }
        })
    };
    const handleDurationUpdate = debounce((duration: number) => {
        saveSettings({
            ...settings,
            midi: {
                ...settings.midi,
                duration,
            }
        })
    }, 250);
    const handleSpacingUpdate = debounce((spacing: number) => {
        saveSettings({
            ...settings,
            midi: {
                ...settings.midi,
                spacing,
            }
        })
    }, 250);
    const handleVolumeUpdate = debounce((volume: number) => {
        (midiRef as any).setMasterVolume(volume/10)
        saveSettings({
            ...settings,
            midi: {
                ...settings.midi,
                volume,
            }
        })
    }, 250);
    return (
        <div className="w-full">
            <h2 className="font-bold text-lg text-left">Midi Settings</h2>
            <Slider onChange={handleVolumeUpdate} defaultValue={settings?.midi?.volume ?? 7.5} label="Volume" min={0} max={11} step={1} />

            <div className='my-2'>
                <div className='text-left text-sm font-bold'> Play Style </div>
                <PlayTypeToggle />
            </div>
            <div className='my-2'>
                <div className='text-left text-sm font-bold'> Instrument </div>
                <Select options={instrumentOptions} selectOption={handleInstrumentUpdate} selectedOption={settings.midi.instrument} />
            </div>
            <Slider onChange={handleDurationUpdate} defaultValue={settings?.midi?.duration ?? 350} label="Duration(ms)" min={50} max={2000} step={50} />
            <Slider onChange={handleSpacingUpdate} defaultValue={settings?.midi?.spacing ?? 150} label="Spacing(ms)" min={0} max={500} step={10} />
        </div>
    )
}

export default MidiSettings