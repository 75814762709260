import React, { useState } from 'react'
import Select from '../../components/common/select/select'
import { Mode } from "tonal";
import { AllKey, splitChord, Suffix } from '../../static/chords';
import Chord from '../../components/chord/chord';
import ChordsSection from './chords_section';
import { titleCase } from '../../components/common/utils';
import Title from '../../components/common/title/title';

const modesOptions = Mode.names().map(mode => ({ label: titleCase(mode), value: mode, }));
function BorrowedChordSection({ tonic, degree, mode }: { tonic: string, degree?: number, mode: string }) {
    // Mode.names().forEach(mode => console.log(mode, Mode.seventhChords(mode, 'C')));
    return (
        <div className='w-full'>
            <ChordsSection tonic={tonic as AllKey} mode={mode} degree={degree} />
        </div>
    )
}

export default BorrowedChordSection